import $ from 'jquery'
export default (window.$ = window.jQuery = $);
require('jquery-ui/ui/widget.js');

(function($) {
    $.widget( 'wr.countdown', {
        options: {
            countdown: 'August 16, 2024 21:00:00',
            el: {
                days: '.days',
                hours: '.hours',
                minutes: '.minutes',
                seconds: '.seconds'
            }
        },
        _setOptions: function () {
            // _super and _superApply handle keeping the right this-context
            this._superApply(arguments);
            this._refresh();
        },

        // _setOption is called for each individual option that is changing
        _setOption: function (key, value) {
            // prevent invalid color values
            if (/countdown|el/.test(key) && (value < 0 || value > 255)) {
                return;
            }
            this._super(key, value);
        },
        _create: function() {
            this.countdownDate = new Date(this.options.countdown).getTime();
            this.remaining = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            }
            this.el = {
                days: $(this.options.el.days, this.element),
                hours: $(this.options.el.hours, this.element),
                minutes: $(this.options.el.minutes, this.element),
                seconds: $(this.options.el.seconds, this.element),
            }
            this.dial = $('.dial', this.element);
        },
        _init: function() {
            this.interval = setInterval(this._handleInterval.bind(this) , 1000);
        },
        _handleInterval: function() {
            var now = new Date();
            var distance = this.countdownDate - now.getTime();

            // Time calculations for days, hours, minutes and seconds
            this.remaining = {
                days: Math.floor(distance / (1000 * 60 * 60 * 24)),
                hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((distance % (1000 * 60)) / 1000)
            }

            // Display the result
            for (var unit in this.options.el) {
                this.el[unit].html(this._formatDateUnit(unit, this.remaining[unit]));
            }

            // Light up dial
            this.dial.find('span').removeClass('active');
            this.dial.find('span:nth-child(' + (now.getSeconds()%10 + 1) +')').addClass('active');

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(this.interval);
                for (var unit in this.options.el) {
                    this.el[unit].html(this._formatDateUnit(unit, this.remaining[unit]));
                }
            }
        },
        _formatDateUnit: function(unit, digit) {
            if (digit >= 10) return digit;
            // if (digit >= 10 || unit == 'hours')
            //     return digit;
            return '0' + digit;
        }
    });
    $(document).ready(function() {
        $('.component-countdown').countdown();
        setTimeout(function(){ $('.component-countdown').fadeIn(); }, 1000);
    });
})(jQuery);
