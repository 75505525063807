// import * as bootstrap from 'bootstrap'
import $ from 'jquery'
// import "@lottiefiles/lottie-player"

$('body').addClass('loaded')

import './css/global.scss'

import './components/countdown/countdown.scss'
import './components/countdown/countdown'

import './components/footer/footer.scss'

import './components/instagram/instagram.scss'